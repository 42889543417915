import styled from 'styled-components'
import {
  xl,
  lg,
  md,
  sm,
  sh,
  themeColor,
  black,
  headingFont,
} from 'config/variables'

export const SimformGuaranteeSecStyles = styled.section` 
  overflow: hidden;
  .heading-wrapper {
    max-width: 670px;
  }
  .heading-para {
    max-width: 690px;
    margin: 0 auto;
    text-align: center;
    ${sh(`
      max-width: 615px;
    `)}
    ${md(`
      display: none;
    `)}
    p {
      font-size: 24px;
      letter-spacing: -0.15px;
      line-height: 33px;
      margin-bottom: 34px;
      ${sh(`
        font-size: 21px;
        line-height: 30px;
      `)}
      ${md(`
        font-size: 20px;
        line-height: 24px;
      `)}
      ${sm(`
        font-size: 18px;
        line-height: 28px;
        `)}
    }
  }
  .simformGuaranteeInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${lg(`
      flex-direction: column-reverse;
    `)} 
    .simformGuaranteeThumb {
      flex: 0 1 700px;
      padding: 50px 50px 0;
      position: relative;
      ${xl(`
         padding: 30px 30px 0;
      `)}  
      ${lg(`
        padding: 30px 30px 0;
        flex: 1 1 auto 
        width: 100%;
      `)} 
      ${md(` 
        padding: 15px 15px 0;
      `)} 
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 20px;
        opacity: 0.24;
        border-radius: 6px;
        background-color: #cee4ff;
        z-index: 0;
      }
      .sgVideoLeftPattern {
        position: absolute;
        bottom: 80px;
        left: -34px;
        ${md(` 
          bottom: 30px;
        `)} 
      }
      .sgVideoRightPattern {
        position: absolute;
        top: 26px;
        right: -34px;
      }
      .video-wrapper {
        position: relative;
        z-index: 1;
      }
    }
    .simformGuaranteeContent {
      flex: 0 1 440px;
      padding-left: 45px;
      ${xl(`
        padding-left: 30px;
      `)}
      ${lg(`
        flex: 1 1 auto
        padding-left:0;
        width: 100%;
        margin-bottom: 30px;
      `)} 
      h2 {
        margin: 0 0 30px;
      }
      .guaranteeList {
        list-style: none;
        margin-bottom: 30px;
        li {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          .icon {
            height: 43px;
            width: 43px;
            flex: 0 0 43px;
          }
          .guaranteeInfo {
            flex: 1 1 auto;
            padding-left: 16px; 
            h6 {
              font-size: 18px;
              font-weight: 500;
              line-height: 1.5;
              letter-spacing: -0.17px;
              margin: 0;
            }
            p {
              font-size: 17px;
              font-weight: normal;
              line-height: 1.5;
              letter-spacing: -0.17px; 
              margin: 0;
            }
          }
        }
      }
      .btn-read {
        font-size: 18px;
        line-height: 24px;
        margin-top: 23px;
        font-weight: 500;
        position: relative; 
        color: ${themeColor};
        transition: all 0.5s ease;
        display: inline-block;
        .line {
          width: 60px;
          height: 3px;
          background: #EF5366;
          border-radius: 1px;
          display: block;
          -webkit-transition: all 0.5s ease;
          transition: all 0.5s ease;
          position: absolute;
          top: -20px;
          &::before {
            content: '';
            height: 100%;
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
          }
        } 
        &:hover {
          color: ${black};
          .line {
            &::before {
              width:100%;
            }
          }
        }
      }
    }
  }
`
