import React from 'react'
import { SimformGuaranteeSecStyles } from './SimformGuaranteeSec.styles'
import Image from 'components/ImgOptimized'
import Simformvideo from 'components/Simformvideo/Simformvideo'
import Button from 'components/Button/Button'
import { images } from 'config/images'

function SimformGuaranteeSec(props) {
  const { SimformGuaranteeProps } = props
  return (
    <SimformGuaranteeSecStyles id="simform-video">
      <div className="container">
        {SimformGuaranteeProps.buildApplicationHeading && (
          <div className="heading-wrapper">
            <h2 className="h3">
              {SimformGuaranteeProps.buildApplicationHeading}
            </h2>
          </div>
        )}
        {SimformGuaranteeProps.buildAppPara && (
          <div className="heading-para">
            <p>{SimformGuaranteeProps.buildAppPara}</p>
          </div>
        )}
        <div className="simformGuaranteeInner">
          <div className="simformGuaranteeThumb">
            <img
              className="sgVideoLeftPattern"
              src={images.sgVideoLeftPattern}
              alt="Video Left"
            />
            <img
              className="sgVideoRightPattern"
              src={images.sgVideoRightPattern}
              alt="Video Right"
            />
            <Simformvideo
              autoPlay={SimformGuaranteeProps.autoPlay}
              buildVid={SimformGuaranteeProps.buildVid}
              lightboxImgVid={SimformGuaranteeProps.lightboxImgVid}
              posterAlt={SimformGuaranteeProps.posterAlt}
              posterImage={props.posterImage}
            />
          </div>
          <div className="simformGuaranteeContent">
            <h2>{SimformGuaranteeProps.title}</h2>
            <ul className="guaranteeList">
              {SimformGuaranteeProps.GuaranteeList.map((data, i) => {
                return (
                  <li key={i}>
                    <div className="icon">
                      <img src={data.icon} alt={data.guaranteeTitle} />
                    </div>
                    <div className="guaranteeInfo">
                      <h6>{data.guaranteeTitle}</h6>
                      <p>{data.guaranteeInfo}</p>
                    </div>
                  </li>
                )
              })}
            </ul>
            <Button
              type={SimformGuaranteeProps.type}
              buttonLink={SimformGuaranteeProps.Link}
              buttonName={SimformGuaranteeProps.ButtonText}
            />
          </div>
        </div>
      </div>
    </SimformGuaranteeSecStyles>
  )
}

export default SimformGuaranteeSec
