import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const AmazonWebServicesProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Deliver custom Gen AI and cloud{' '}
        <span className="heading-red">solutions using AWS ecosystem</span>
      </>
    ),
    bannerContent:
      'As an AWS Premier Consulting Partner, Simform leverages the power of Gen AI to enhance cloud applications. Our 200+ AWS certified professionals specialize in optimizing a wide range of AWS services, including but not limited to compute, storage, databases, analytics, and security.',
    BannerAlt: 'Amazon Web Services',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        <span className="highlight-head">Simform and AWS</span> - Aligned for
        Impact
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            Simform maintains a tightly aligned partnership with AWS – combining
            certified talent, proven methodologies, and purpose-built solutions
            to help enterprises transform digitally.
            <br /> <br />
            We help companies build next-generation applications on AWS,
            ensuring data privacy and security. With AWS native services for Gen
            AI, we deploy the latest, high-performance models to protect your
            business, customers, and compliance obligations.
            <br />
            <br />
            Acknowledged by AWS for our competencies and programs, we offer
            advisory services, cloud migrations, implementations, and managed
            services, with a strong emphasis on efficient migration and
            modernization. We also aim to enhance applications, refine customer
            experiences, increase team efficiency, and streamline business
            processes.
          </>
        ),
      },
      {
        detailsList: [
          '200+ Trained AWS Experts',
          '7+ Competencies & 16+ SDPs',
          'Strategic Cloud Advisors',
          'Security and Compliance Focus',
          'Assured Quality Standards',
          'Advanced AWS Strategies',
          'Customized Cloud Solutions',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of Working With
        <br /> <span className="highlight-head"> Simform for AWS</span>
      </>
    ),
    processPara:
      'Simform, an AWS Premier Consulting Partner, secures your cloud innovation with certified talent, proven methodologies, full-stack services, security-first approaches, and complete AWS ownership.',
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'AWS Certified Experts',
        workingBenefitsContent:
          'Our team, as an AWS Premier Consulting Partner, boasts over 200 AWS certified professionals—cloud architects, developers, DevOps engineers, and more—meticulously aligned with your cloud requirements.',
        benefitImgAlt: 'AWS Certified Experts',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Specialized AWS Competencies',
        workingBenefitsContent:
          'Holding key competencies, including AWS Migration, we offer comprehensive guidance covering advisory, security, migration, and the ongoing management and optimization of your complex AWS workloads.',
        benefitImgAlt: 'Specialized AWS Competencies',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Future-Proof AWS Methodologies',
        workingBenefitsContent:
          'Following AWS best practices, we adhere to well-architected frameworks, ensuring high availability, cost optimization, security, performance efficiency, and operational excellence. This approach builds boundaryless cloud applications for the future.',
        benefitImgAlt: 'Future-Proof AWS Methodologies',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'End-to-End AWS Services',
        workingBenefitsContent:
          'We take charge of your entire AWS cloud environment, overseeing migration, implementation, development, and ongoing DevSecOps, FinOps, and production support. This ensures maximum innovation velocity and business outcomes.',
        benefitImgAlt: 'End-to-End AWS Services',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Ensuring Quality and Governance',
        workingBenefitsContent:
          'Our approach involves integrating robust quality assurance, security controls, compliance processes, and reliability best practices into your cloud deployments. This is done in accordance with both AWS and industry standards.',
        benefitImgAlt: 'Ensuring Quality and Governance',
      },
    ],
  },
  customDevProps: {
    serviceHeading: (
      <>
        <span className="highlight-head">AWS</span> services and solutions
      </>
    ),
    para: `As a premier consulting partner, we stand out for our focused alignment with AWS and tailored offerings across AWS's entire spectrum of over 200 cloud services, serving various industry verticals. Our execution-driven approach ensures sharp, effective solutions that seamlessly align with AWS standards.`,
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'SaaS Development Services',
        serviceLink: '/services/aws-saas-development/',
        linkType: EXTERNAL_LINK,
        serviceParagraph:
          'Build, deliver, and scale SaaS innovation rapidly by leveraging AWS well-architected serverless platforms, architectural best practices, and managed services.',
        serviceIcon: images.awsSaasIcon,
        serviceAlt: 'Mobile',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Generative AI',
        serviceLink: '/services/aws-generative-ai/',
        linkType: EXTERNAL_LINK,
        serviceParagraph:
          'Guide you through model selection, data curation, and AWS integration to optimize deployment and scaling, boosting your Generative AI performance and cost-efficiency.',
        serviceIcon: images.sartificialIntelligence,
        serviceAlt: 'Performance',
        serviceClass: 'very-light-pink-three',
      },
      {
        id: '3',
        serviceHeading: 'DevOps Consulting Services',
        serviceLink: '/services/aws-devops/',
        linkType: EXTERNAL_LINK,
        serviceParagraph:
          'Drive rapid feature delivery and IT productivity improvements through cultural, process, and technical transformations, CI/CD automation, and containerization on AWS native services.',
        serviceIcon: images.devopsConsultingMicon,
        serviceAlt: 'Manual',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: 'Cloud Solution for SMB',
        serviceLink: '/services/aws-cloud-smb/',
        linkType: EXTERNAL_LINK,
        serviceParagraph:
          'Tackle small business challenges around flexibility, simplicity, and TCO while laying solid cloud foundations for future innovation and results.',
        serviceIcon: images.iconBusinessApp,
        serviceAlt: 'Software',
        serviceClass: 'pale',
      },
      {
        id: '5',
        serviceHeading: 'Cloud Migration',
        serviceLink: '/services/aws-cloud-migration/',
        linkType: EXTERNAL_LINK,
        serviceParagraph:
          'Mitigate risks and maximize ROI by replatforming legacy systems onto optimized AWS cloud environments using our proven 3-step methodology.',
        serviceIcon: images.cloudMigrationMicon,
        serviceAlt: 'Devops',
        serviceClass: 'pale-lavender',
      },
      {
        id: '6',
        serviceHeading: 'Data Engineering',
        serviceLink: '/services/aws-data-engineering/',
        serviceParagraph:
          'Architect reliable data pipelines, warehouses, and machine learning platforms to extract strategic insights from your data assets in real-time.',
        serviceIcon: images.biDataEngineeringMicon,
        serviceAlt: 'Performance',
        serviceClass: 'very-light-pink-three',
      },
      {
        id: '7',
        serviceHeading: 'Serverless and Orchestration',
        serviceLink: '/services/aws-serverless-and-orchestration/',
        serviceParagraph:
          'Create innovative, event-driven, and containerized applications that prioritize differentiation, optimizing costs and engineering productivity behind the scenes.',
        serviceIcon: images.serverlessMicon,
        serviceAlt: 'Performance',
        serviceClass: 'very-light-pink-three',
      },
      {
        id: '8',
        serviceHeading: 'Cloud Management',
        serviceLink: '/services/aws-cloud-management/',
        serviceParagraph:
          'Scale cloud innovation rapidly across the enterprise by embedding consistency in governance, automation, FinOps, and other cloud management disciplines.',
        serviceIcon: images.scloudApp,
        serviceAlt: 'Performance',
        serviceClass: 'very-light-pink-three',
      },
      {
        id: '9',
        serviceHeading: 'Secure & Resilient Solutions',
        serviceLink: '/services/aws-secure-resilient-solutions/',
        serviceParagraph:
          'Build fault-tolerant systems, configure firewalls, and implement automatic failover mechanisms to minimize downtime, prevent breaches, and ensure compliance.',
        serviceIcon: images.iconSecureResilient,
        serviceAlt: 'Performance',
        serviceClass: 'very-light-pink-three',
      },
      {
        id: '10',
        serviceHeading: 'Advertising and Marketing Technology',
        serviceLink: '/services/aws-advertising-and-marketing-technology/',
        linkType: EXTERNAL_LINK,
        serviceParagraph:
          'Derive actionable consumer insights to craft targeted omnichannel campaigns through AWS machine learning, EMR data lakes, Kafka streams, and purpose-built analytics.',
        serviceIcon: images.smarterItOutsourcingMicon,
        serviceAlt: 'Mobile',
        serviceClass: 'pale-grey',
      },
    ],
  },

  industryProps: {
    serviceHeading: 'By Industry',
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Retail Services',
        serviceLink: '/services/aws-retail/',
        linkType: EXTERNAL_LINK,
        serviceParagraph:
          'Deliver personalized and seamless shopping experiences across channels through customer data, machine learning forecasts, and microservices on AWS.',
        serviceIcon: images.iconEcommerceWeb,
        serviceAlt: 'Mobile',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Healthcare Services',
        serviceLink: '/services/aws-healthcare/',
        linkType: EXTERNAL_LINK,
        serviceParagraph:
          'Transform care delivery, accelerate discoveries and improve patient outcomes by leveraging healthcare data on secure and scalable cloud infrastructure.',
        serviceIcon: images.iconHealthBenefits,
        serviceAlt: 'Mobile',
        serviceClass: 'pale-grey',
      },
      {
        id: '3',
        serviceHeading: 'Supply Chain Services',
        serviceLink: '/services/aws-supply-chain/',
        linkType: EXTERNAL_LINK,
        serviceParagraph:
          'Orchestrate efficient, transparent, and resilient operations. Derive real-time visibility and intelligent recommendations through IoT analytics and AI across your supply chain on AWS.',
        serviceIcon: images.carrierDispatchIcon,
        serviceAlt: 'Manual',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: 'Finance Services',
        serviceLink: '/services/aws-financial/',
        linkType: EXTERNAL_LINK,
        serviceParagraph:
          'Innovate faster in highly regulated spaces with AWS purpose-built capabilities for security, privacy, compliance, ML-based fraud detection, and other financial services. ',
        serviceIcon: images.iconCost,
        serviceAlt: 'Devops',
        serviceClass: 'pale-lavender',
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        <span className="highlight-head">AWS</span> Case Studies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Gavel – A live auction platform that combines live video shopping with live auctions',
        caseStudiesParaGraph:
          '...Gavel is a live auction platform that brings collectors, buyers, and sellers together…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/gavel/',
      },
      {
        caseStudiesTitle:
          'TagB - A scalable parking and valet management system',
        caseStudiesParaGraph:
          '…Tag B Group is a well-known transportation, parking, garage management, and…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/TagB/',
      },
      {
        caseStudiesTitle:
          'MediaCentric - An app that helps streamline the on-field work.',
        caseStudiesParaGraph:
          '...The client, a leading U.S. full-service organization in the AV/IT industry, needed...',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/mediacentric/',
      },
      {
        caseStudiesTitle:
          'CarSaver - A complete one-stop solution for all your car buying requirements',
        caseStudiesParaGraph:
          '…CarSaver’s platform offers an all-in-one solution for buying, renting, leasing, insuring, and...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/carsaver/',
      },
      {
        caseStudiesTitle:
          '3Birds - A digital marketing platform to improve their customer engagement and sales.',
        caseStudiesParaGraph:
          '…3 Birds provides automotive dealerships with a digital marketing platform to improve their customer…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/3birds/',
      },
    ],
  },
  AboutprojectformProps: {
    title: (
      <>
        Tell Us About <span className="highlight-head">Your Project</span>
      </>
    ),
    formTitleL: 'Request a Free Quote',
    formInfo: 'Guaranteed response within one business day.',
    formDetailstitle: 'What will happen next?',
    formButtonText: 'LET"S GET STARTED',
    list: [
      'We’ll reach out to you within 24 hours.',
      'We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.',
      'You can start 15-days risk-free trial with us.',
    ],
  },

  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/blog/aws-graviton-service-delivery/',
        featurecardTitle:
          'Simform Achieves AWS Graviton Service Delivery Partnership',
      },
      {
        blogLink:
          'https://www.simform.com/blog/aws-saas-competency-accelerate-business-growth/',
        featurecardTitle:
          'Accelerate Business Growth with an AWS SaaS Competency Partner',
      },
      {
        blogLink: 'https://www.simform.com/blog/aws-resilience-competency/',
        featurecardTitle: 'Simform Achieves AWS Resilience Competency',
      },
    ],
  },
}
