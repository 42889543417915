import React from 'react'
import { navigate } from 'gatsby'
import loadable from '@loadable/component'
// import { formNames } from './AboutprojectConstants'
import * as Yup from 'yup'

import { AboutprojectformStyles } from './Aboutprojectform.styles'

import {
  addPipeDrivePerson,
  addPipeDriveDeal,
  fetchKeywords,
  sendMail,
} from 'config/pipedrive'
import {
  getPageLink,
  getPageType,
  formTypes,
  sendFormSuccessGAEvent,
  logError,
} from 'config/utils'
import {
  formFields,
  formNames,
} from 'components/ContactStepForm/contactFormConstants'

const FormikWrapper = loadable.lib(() => import('formik'))

function Aboutprojectform(props) {
  const { AboutprojectformData } = props

  const validationSchema = () => {
    return Yup.object().shape({
      [formNames.email]: Yup.string()
        .email()
        .required(`Please enter a valid email address`),
      // [formNames.FirstName]: Yup.string().required('First Name is required'),
      // [formNames.LastName]: Yup.string().required('Last Name is required'),
      [formNames.name]: Yup.string().required('Name is required'),
      [formNames.phone]: Yup.string().required('Phone Number is required'),
      // [formNames.interestedIn]: Yup.string().required(
      //   'interested in is required'
      // ),
      [formNames.projectDescription]: Yup.string().required(
        'project description is required'
      ),
    })
  }
  return (
    <AboutprojectformStyles>
      <div className="container">
        <div className="heading-wrapper">
          <h2>{AboutprojectformData.title}</h2>
        </div>
        <div className="form-wrapper">
          <div className="form-col">
            <div className="form-head">
              <h5>{AboutprojectformData.formTitle}</h5>
              <p>{AboutprojectformData.formInfo}</p>
            </div>
            <FormikWrapper>
              {({ Formik }) => (
                <Formik
                  validationSchema={validationSchema()}
                  initialValues={{
                    [formNames.email]: '',
                    // [formNames.FirstName]: '',
                    // [formNames.LastName]: '',
                    [formNames.name]: '',
                    [formNames.phone]: '',
                    //[formNames.interestedIn]: '',
                    [formNames.projectDescription]: '',
                  }}
                  onSubmit={async (
                    values,
                    { setSubmitting, setErrors, setStatus, resetForm }
                  ) => {
                    try {
                      if (window.TriblioAnalyticsObject) {
                        const form = document.querySelector(
                          '#about-project-form'
                        )
                        const data = new FormData(form)
                        const formData = new URLSearchParams(data).toString()
                        const env = {
                          fd: formData,
                        }
                        window.TriblioAnalyticsObject.track(40, env)
                      }

                      let addPerson = null
                      const personObj = {
                        [formNames.name]: values.name,
                        [formNames.email]: values.email,
                        [formNames.phone]: values.phone,
                      }
                      addPerson = await addPipeDrivePerson(personObj)

                      const dealObj = {
                        title: values[formNames.name],
                        person_id: addPerson.data.id,
                        [formFields.projectDescription]:
                          values[formNames.projectDescription],
                        //  [formFields.interestedIn]:
                        //   values[formNames.interestedIn],
                        [formFields.pageType]: getPageType(),
                        [formFields.pageLink]: getPageLink(),
                      }
                      await addPipeDriveDeal({
                        ...dealObj,
                        ...fetchKeywords(),
                      })

                      let emailFields = {
                        ...values,
                        [formNames.pageType]: getPageType(),
                        [formNames.pageLink]: getPageLink(),
                        ...fetchKeywords(),
                      }

                      sendMail({ emailFields })
                      // autopilotAdd({
                      //   fields: emailFields,
                      //   _autopilot_session_id: document.querySelector(
                      //     'input[name="_autopilot_session_id"]'
                      //   ).value,
                      // })
                      sendFormSuccessGAEvent()
                      resetForm()
                      setSubmitting(false)
                      if (getPageType() === formTypes.ppc) {
                        this.setState({ isOpen: true })
                      } else {
                        navigate('/thank-you/?from=footer-form')
                      }
                    } catch (error) {
                      logError(error)
                    }
                  }}
                >
                  {({ values, handleChange, handleSubmit, errors }) => (
                    <form id="about-project-form" onSubmit={handleSubmit}>
                      <div className="input-field-row">
                        <div className="form-field-blk">
                          <input
                            className={`label-input-field ${
                              values[formNames.name].length ? 'fill' : ''
                            }`}
                            type="text"
                            onChange={handleChange}
                            value={values[formNames.name]}
                            name={formNames.name}
                            id="apname"
                          />
                          <label htmlFor={formNames.name}>Name</label>
                        </div>
                      </div>
                      <div className="input-field-row">
                        <div className="form-field-blk">
                          <input
                            className={`label-input-field ${
                              values[formNames.email].length ? 'fill' : ''
                            }`}
                            type="email"
                            onChange={handleChange}
                            value={values[formNames.email]}
                            name={formNames.email}
                            id="apemail"
                          />
                          <label htmlFor={formNames.email}>Email</label>
                        </div>
                      </div>
                      <div className="input-field-row">
                        <div className="form-field-blk">
                          <input
                            className={`label-input-field ${
                              values[formNames.phone].length ? 'fill' : ''
                            }`}
                            type="phone"
                            onChange={handleChange}
                            value={values[formNames.phone]}
                            name={formNames.phone}
                            id="apphone"
                          />
                          <label htmlFor={formNames.phone}>Phone Number</label>
                        </div>
                      </div>
                      {/* <div className="input-field-row">
                        <div className="form-field-blk">
                          <input
                            className={`label-input-field ${
                              values[formNames.interestedIn].length
                                ? 'fill'
                                : ''
                            }`}
                            type="text"
                            onChange={handleChange}
                            value={values[formNames.interestedIn]}
                            name={formNames.interestedIn}
                            id="interested"
                          />
                          <label htmlFor={formNames.interestedIn}>
                            I'm Interested in
                          </label>
                        </div>
                      </div> */}
                      <div className="input-field-row">
                        <div className="form-field-blk textarea-row">
                          <textarea
                            className={`textarea label-input-field ${
                              values[formNames.projectDescription].length
                                ? 'fill'
                                : ''
                            }`}
                            onChange={handleChange}
                            value={values[formNames.projectDescription]}
                            name={formNames.projectDescription}
                            id="apmessage"
                          ></textarea>
                          <label htmlFor={formNames.projectDescription}>
                            Message
                          </label>
                        </div>
                      </div>
                      <div className="bottomAlert">
                        <div className="submit-row">
                          <button
                            id="apsubmit"
                            className="btn-submit"
                            type="submit"
                          >
                            {AboutprojectformData.formButtonText}
                          </button>
                        </div>
                        {Object.entries(errors).length > 0 &&
                        errors.constructor === Object ? (
                          <div>
                            <div className="bottomErrorText">
                              Please fill all the required fields
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </form>
                  )}
                </Formik>
              )}
            </FormikWrapper>
          </div>
          <div className="form-details">
            <h3>{AboutprojectformData.formDetailstitle}</h3>
            <ol type="123">
              {AboutprojectformData.list.map((item, i) => {
                return <li key={i}>{item}</li>
              })}
            </ol>
          </div>
        </div>
      </div>
    </AboutprojectformStyles>
  )
}

export default Aboutprojectform
