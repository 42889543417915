import React, { Component } from 'react'
import SingleService from 'components/SingleService/SingleService'
import styled from 'styled-components'
import { lg, sm, device, md, sh } from 'config/variables'

const CustomDevBlock = styled.section`
  margin-bottom: 90px;
  ${lg(`
    margin-bottom: 50px;
  `)}
  ${sm(`
    margin-bottom: 80px;
  `)}
  .heading-wrapper {
    max-width: 670px;
    p {
      font-size: 24px;
      letter-spacing: -0.15px;
      line-height: 34px;
      margin-bottom: 30px;
      ${sh(`
        font-size: 21px;
        line-height: 31px;
      `)}
      ${md(`
        font-size: 20px;
        line-height: 28px;
        `)}
      ${sm(`
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 20px;
        `)}
      strong {
        font-weight: 500;
      }
      .text-bg {
        position: relative;
        color: #cb5fb9;
        &:after {
          content: '';
          position: absolute;
          height: 15px;
          background: #ffe0f9;
          bottom: 5px;
          left: 0;
          width: 100%;
          z-index: -1;
          ${sm(`
            height: 7px;
            bottom: 3px;
            `)}
        }
      }
    }
  }
  .custom-dev-listing {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    border: 1px solid #d8d8d8;
    border-right: 0;
    border-bottom: 0;
    ${lg(`
      flex-wrap: nowrap;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      border: 0;
    `)}
    .service-single {
      max-width: 33.33%;
      border: 1px solid #d8d8d8;
      border-top: 0;
      border-left: 0;
      box-shadow: inherit;
      padding: 24px;
      &:hover {
        transform: translateY(0);
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.05),
          0 9px 46px 8px rgba(0, 0, 0, 0.01),
          0 24px 38px 3px rgba(0, 0, 0, 0.05);
        position: relative;
        z-index: 1;
        ${device(`
          box-shadow: inherit;
        `)}
      }
      ${lg(`
          min-width: 314px;
          margin-right: 20px;
          border: 1px solid #d8d8d8;
          padding: 20px;
      `)}
      ${sm(`
        min-width: 270px;
      `)}
      .service-img-blk {
        ${sm(`
          margin-bottom: 15px;
        `)}
      }
    }
  }
`

class CustomDevelopment extends Component {
  render() {
    const { customServicedata, className } = this.props
    return (
      <CustomDevBlock className={className}>
        <div className="container">
          <div className="heading-wrapper">
            <h2>{customServicedata.serviceHeading}</h2>
            <p>{customServicedata.para}</p>
          </div>
          <div className="custom-dev-listing">
            {customServicedata.serviceContents.map((serviceContent, i) => {
              return <SingleService key={i} {...serviceContent} />
            })}
          </div>
        </div>
      </CustomDevBlock>
    )
  }
}

export default CustomDevelopment
