import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import styled from 'styled-components'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import { lg, md, sh, xl } from 'config/variables'
import { images } from 'config/images'

import FeatureResources from 'components/FeatureResources/FeatureResources'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import CustomDevelopment from 'components/CustomDevelopment/CustomDevelopment'
import CaseStudySlider from 'components/CaseStudySlider/CaseStudySlider'
import Aboutprojectform from 'components/Aboutprojectform/Aboutprojectform'
import SimformGuaranteeSec from 'components/SimformGuaranteeSec/SimformGuaranteeSec'
import { AmazonWebServicesProps } from 'components/Props/amazon-web-services'

export const AmazonWebServicesPage = styled.div`
  .banner {
    .banner-content {
      max-width: 980px;
      p {
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
    .banner-img {
      display: block;
    }
  }
  .buildapp-section {
    margin-bottom: 0px;
  }
  .container {
    .service-right-wrapper {
      .service-right-section {
        .service-content-block {
          .blog-listing-wrapper {
            .blog-listing {
              .single-blog-card {
                figure {
                  min-height: 177px;
                  ${md(`        
                    min-height: 151px;
                  `)}
                }
              }
            }
          }
        }
      }
    }
  }
  .feature-resource-section {
    background: linear-gradient(to top, #eef2ff, #ffffff);
    .contact-info-card {
      display: none;
    }
  }
  .custom-development-sec {
    &.mt-120 {
      margin-top: 120px;
      ${sh(`
        margin-top: 100px;
      `)}
      ${xl(`
        margin-top: 80px;
      `)}
      ${lg(`
        margin-top: 60px;
      `)}
      ${md(`
        margin-top: 40px;
      `)}
    }
    .heading-wrapper {
      max-width:960px;
    }
    .custom-dev-listing  {
      .service-single {
        max-width: 50%;
      }
    }
  }
`

const AmazonWebServices = memo(props => {
  const { data } = props
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
    props.data.process4.childImageSharp.fluid,
    props.data.process5.childImageSharp.fluid,
  ]
  return (
    <Layout
      mainClass="software-dev-services services-page"
      location={location}
      data={{
        img: images.contactHiren,
        img2x: images.contactHiren_2x,
        name: 'Hiren Dhaduk',
        description:
          'Creating a tech product roadmap and building scalable apps for your organization.',
      }}
    >
      <SEO
        title="AWS Premier Partner"
        description="Simform, an AWS Premier Consulting Partner, helps enterprises across multiple industry verticals to transform legacy establishments into dynamic and boundaryless applications."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/amazon-web-services/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/amazon-web-services/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <AmazonWebServicesPage>
        <Banner
          bannerData={AmazonWebServicesProps}
          showBannerImage={true}
          {...props}
        />
        <div className="lazy-load-div">
          <BuildApplication buildApplicationData={AmazonWebServicesProps} />
        </div>
        {showContent && (
          <>
            <CustomDevelopment
              className="custom-development-sec mt-120"
              customServicedata={AmazonWebServicesProps.customDevProps}
            />
            <CustomDevelopment
              className="custom-development-sec"
              customServicedata={AmazonWebServicesProps.industryProps}
            />
            <ProcessFlow
              ProcessFlowData={AmazonWebServicesProps.workingBenefitsProps}
              items={processFlowItem}
            />
            <CaseStudySlider
              caseStudydata={AmazonWebServicesProps}
              {...props}
            />
            <Aboutprojectform
              AboutprojectformData={
                AmazonWebServicesProps.AboutprojectformProps
              }
            />
            <SimformGuaranteeSec
              SimformGuaranteeProps={
                AmazonWebServicesProps.SimformGuaranteeData
              }
              posterImage={posterImage}
            />
            <FeatureResources
              featureresourceData={AmazonWebServicesProps}
              {...props}
            />
          </>
        )}
      </AmazonWebServicesPage>
    </Layout>
  )
})

export default AmazonWebServices

export const query = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/aws-hero-img@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/aws-graviton-service-delivery@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-aws-saas-competency@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-aws-resilience-competency@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(relativePath: { regex: "/aws-eminent-expertise.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(
      relativePath: { regex: "/specialized-aws-competencies@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(relativePath: { regex: "/scalable-team@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process4: file(relativePath: { regex: "/customize-security.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process5: file(relativePath: { regex: "/test-automation-2.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case1: file(relativePath: { regex: "/case-gavel-thumb@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-tagb-thumb@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(relativePath: { regex: "/case-mediacentric-thumb@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-carsaver-thumb@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/case-3birds-thumb@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
