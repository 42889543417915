import styled from 'styled-components'
import {
  themeColor,
  white,
  sm,
  sh,
  md,
  headingFont,
  xl,
  lg,
  metaFont,
} from 'config/variables'
export const AboutprojectformStyles = styled.div`
 margin-bottom: 140px;
 padding-bottom: 50px;
 background-image: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f8f9ff);
  ${xl(`
    margin-bottom: 100px;
  `)}
  ${lg(`
    margin-bottom: 90px;
  `)}
   ${md(`
    margin-bottom: 40px;
  `)}
.form-wrapper {
  margin-top: 100px;
  display: flex;
  align-items: center;
  ${xl(`
    margin-top: 80px;
  `)}
  ${lg(`
    margin-top: 60px;
    flex-direction: column;
    align-items: flex-start;
  `)}
   ${md(`
    margin-top: 40px;
  `)}
  .form-col {
    border-radius: 3px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    background-color: ${white};
    padding:20px 24px 26px;
    flex: 0 0 540px;
    width: 540px;
    max-width: 540px;
    ${lg(`
    flex: 1 1 auto;
    max-width:100%;
    width:100%; 
  `)}
    .form-head {
      text-align: center;
      margin-bottom: 27px;
      h5 {
        margin: 0;
      }
      p {
        letter-spacing: -0.22px;
        font-size: 20px;
        font-family: ${headingFont};
        margin: 0;
        color: rgba(61, 61, 61, 0.5);
      }
    }
    form {
      .input-field-row {
        display: flex;
        margin-bottom: 16px;
        justify-content: space-between;
        position: relative;
        .form-field-blk {
          position: relative;
          width: 100%;
          &.column {
            width: 48%;
          }
          .label-input-field {
            width: 100%;
            height: 55px;
            max-width: 100%;
            margin-bottom: 0px;
            padding: 16px 20px 17px 22px;
            border: 1px solid #e8e9f3;
            border-radius: 5px;
            background-color: #f9fcfe;
            background-image: linear-gradient(180deg, transparent, transparent);
            transition: all 300ms ease;
            color: #3d3d3d;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.17px;
            appearance: none;
            outline: none;
            ${sh(`
              height: 50px;
              padding: 14px 18px 15px 20px;
            `)}
            ${sm(`
              padding: 11px 15px 6px 16px;
              height: 40px;
              font-size: 14px;
            `)}
            & + label {
              pointer-events: none;
            }
            &:focus {
              border-color: #29ded5;
            }
            &:focus + label {
              top: -6px;
              font-size: 11px;
              line-height: 12px;
              padding: 0 2px;
              background: #f9fcfe;
              z-index: 9;
            }
            &.fill + label {
              top: -6px;
              font-size: 11px;
              line-height: 12px;
              padding: 0 2px;
              background: #f9fcfe;
              z-index: 9;
            }
          }
          &.textarea-row {  
            .label-input-field {
              &.textarea { 
                height: 162px; 
                resize: none;
              }
            }
          }
          label {
            position: absolute;
            left: 22px;
            top: 16px;
            margin-bottom: 0px;
            transition: all 300ms ease;
            color: rgba(61, 61, 61, 0.5);
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            letter-spacing: -0.17px;
            cursor: text;
            ${sh(`
              left: 18px;
              top: 13px;
              font-size: 15px;
              line-height: 21px;
            `)}
            ${sm(`
              left: 16px;
              top: 9px;
              font-size: 14px;
            `)}
          }
        } 
      } 
      .submit-row {
        margin-top: 19px;
        border-radius: 4px;
        width: 100%;
        background-color: ${themeColor};
        box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
        transition: all 0.15s ease-in-out;
        border: 1px solid ${themeColor};
        position: relative;
        overflow: hidden;
        z-index: 1;
        text-align: center;
        line-height: 21px;
        cursor: pointer;
        ${sm(`
          margin-top: 10px;
        `)}
        .btn-submit {
          width: 100%;
          background: transparent;
          font-size: 16px;
          line-height: 21px;
          font-weight: 600;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          transition: all 0.15s ease-in-out;
          color: ${white};
          border: 0;
          padding: 0;
          position: relative;
          cursor: pointer;
          padding: 14px 15px 14px;
          ${sm(`
            font-size: 14px;
          `)}
        }
        &:hover {
          .btn-submit {
            color: ${themeColor};
          }
        }
        transition: all 0.15s ease;
        transition: color 0.15s ease-in-out;
        display: inline-block;
        &:after,
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: ${white};
          z-index: -1;
        }
        &:before {
          transform: translateY(-100%);
          transition: all 15ms ease-in-out;
        }
        &:after {
          transform: translateY(100%);
          transition: all 0.2s ease-in-out;
        }
        &:hover:before {
          transform: translateY(0);
          transition: all 0.2s ease-in-out;
        }
        &:hover:after {
          transform: translateY(0);
          transition: all 0ms ease-in-out;
          transition-delay: 0.2s;
        }
      }
      .bottomAlert {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 19px;
      }
      .bottomSuccessText {
        color: #3d3d3d;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        padding-left: 12px;
        margin-bottom: -6px;
        ${sm(`
            font-size:14px;
        `)}
      }
      .bottomErrorText {
        margin-top: 15px;
        color: #ef5366;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .form-details {
    flex: 1 1 auto;
    max-width:calc(100% - 540px);
    width:calc(100% - 540px);
    padding-left: 56px;
    ${lg(`
    flex: 1 1 auto;
    max-width:100%;
    width:100%;
    padding-left: 0;
    padding-top: 30px;
  `)}
    h3 {
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -0.33px;
      line-height: 36px;
      margin-bottom: 25px;
      font-family: ${metaFont};
      ${lg(`
      font-size: 26px;
      line-height: 32px;
    `)}
    }
    ol {
      margin: 0;
      padding: 0 0 0 40px;
      li {
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 30px;
        font-weight: 400;
        letter-spacing: -0.26px;
        font-family: ${headingFont};
        color: rgba(61, 61, 61, 0.7);
        ${lg(`
         font-size: 20px;
        line-height: 26px;
      `)}
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
`
