import React, { useState } from 'react'
import { SimformvideoStyles } from './Simformvideo.styles'
import Image from 'components/ImgOptimized'

function Simformvideo(props) {
  const [state, setState] = useState({ addClass: false })
  const playPauseClick = () => {
    // var element = document.getElementById('element')
    setState({ addClass: true })
  }
  return (
    <SimformvideoStyles className="video-wrapper">
      <div className={`app-video-block ${state.addClass ? 'active' : ''}`}>
        {/* <div className="full-screen" onClick={handleClick}>
            <img src={images.fullScreen} alt="" />
          </div> */}
        {props.autoPlay ? (
          // <video
          //   id="element"
          //   autoPlay=""
          //   muted
          //   loop
          //   playsInline
          //   poster={props.poster}
          // >
          //   <source src={props.buildVid} type="video/mp4" />
          // </video>
          <iframe
            id="element"
            src={`https://www.youtube.com/embed/${props.buildVid}/?autoplay=1&rel=0&showinfo=0&controls=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <>
            <iframe
              id="element"
              src={`${
                state.addClass
                  ? `https://www.youtube.com/embed/${props.lightboxImgVid}?autoplay=1&loop=1&rel=0&showinfo=0&controls=0&playlist=${props.lightboxImgVid}`
                  : ''
              }`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div className="poster-wrapper">
              <Image
                fluid={props.posterImage}
                alt={props.posterAlt}
                lazyload={{
                  enabled: false,
                }}
              />
              <button className="play-pause-buttton" onClick={playPauseClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M-12-8h36v36h-36z" />
                    <path
                      fill="#FFF"
                      d="M0 2.233v15.534a1.5 1.5 0 0 0 2.305 1.266l12.206-7.768a1.5 1.5 0 0 0 0-2.53L2.305.967A1.5 1.5 0 0 0 0 2.233z"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </>
        )}
        {/* <LightBoxImgVideo
            lightboxImgVid={[props.lightboxImgVid]}
            lightButtonClassName={'play-pause-buttton'}
            buttonText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="20"
                viewBox="0 0 16 20"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M-12-8h36v36h-36z" />
                  <path
                    fill="#FFF"
                    d="M0 2.233v15.534a1.5 1.5 0 0 0 2.305 1.266l12.206-7.768a1.5 1.5 0 0 0 0-2.53L2.305.967A1.5 1.5 0 0 0 0 2.233z"
                  />
                </g>
              </svg>
            }
          /> */}
      </div>
    </SimformvideoStyles>
  )
}

export default Simformvideo
